








































































import { Vue, Component } from "nuxt-property-decorator";
import GrBottomBackground from "~/components/common/GrBottomBackground.vue";
import GrButton from "~/components/common/GrButton.vue";
import MaxWidthSection from "~/components/common/MaxWidthSection.vue";
import TwoColumnRow from "~/components/common/TwoColumnRow.vue";
import CommonQuestionsTalent from "~/components/startPageNew/CommonQuestionsTalent.vue";
import CompanyCarousel from "~/components/startPageNew/CompanyCarousel.vue";
import GrMoviePlayer from "~/components/startPageNew/GrMoviePlayer.vue";
import GrPublicFooter from "~/components/startPageNew/GrPublicFooter.vue";

// import gsap from "gsap";

@Component({
  middleware: ["campaignCode"],
  components: {
    GrButton,
    MaxWidthSection,
    GrMoviePlayer,
    TwoColumnRow,
    CompanyCarousel,
    CommonQuestionsTalent,
    GrPublicFooter,
    GrBottomBackground,
  },
})
export default class TalentStartPage extends Vue {
  head() {
    return {
      title: "Gritify",
      titleTemplate: "%s",
    };
  }
}
